import React from "react";
import Layout from "../components/Layout";
import { graphql, useStaticQuery } from 'gatsby'
import { Builder, BuilderComponent } from "@builder.io/react";
import Link from '../components/Link';

export default function NotFound() {
  const data = useStaticQuery(graphql`
    query NotFound404 {
      allBuilderModels {
        oneNotFound {
          content
        }
      }
    }
  `);

  if (Builder.isPreviewing || Builder.isEditing) {
    return (
      <Layout>
        <BuilderComponent
          renderLink={Link}
          name={Builder.previewingModel || "page"}
        />
      </Layout>
    );
  }

  const content = data?.allBuilderModels?.oneNotFound?.content;
  return (
    <Layout>
      <BuilderComponent
          renderLink={Link}
          content={content}
          name="404"
        />
    </Layout>
  );
}
